import ng from 'angular';
import { acpDigitalWalletModule } from 'apps/digital-wallet/components/digital-wallet';
import acpCore from 'core';

export const acpDigitalWalletSectionModule = ng.module(
  'acp.section.digital-wallet',
  [acpCore.name, acpDigitalWalletModule.name]
);

export default acpDigitalWalletSectionModule;
export {
  acpDigitalWalletDomainModule
} from 'apps/digital-wallet/components/digital-wallet-domain';
